<template>
    <div class="modal-alert">
        <div class="head flex-row flex-between items-start">
            <div
                    class="title"
                    :class="options.titleClass"
                    v-html="$translate(title)"/>
            <i @click="$emit('close')" class="material-icons">close</i>
        </div>
        <div class="body" v-html="body"/>
        <div class="buttons">
            <button
                    @click="onClickConfirm"
                    class="btn btn-primary"
                    v-html="$translate('CONFIRM')"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalAlert',
    props: ['options'],
    computed: {
        title() {
            return (this.options || {}).title
        },
        body() {
            return (this.options || {}).body
        },
        log() {
            return (this.options || {}).log
        },
    },
    methods: {
        /*
        Modal custom 시 사용할 수 있는 바닐라브릿지 기본 modal
        options로 title, body, log(*router에 push/go하려는 page 수)를 받는다
         */
        onClickConfirm() {
            this.$emit('close', this.log)
        },
    },
}
</script>
